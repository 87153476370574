import React from 'react';
import Layout from '../components/layout';
import Contact from '../scenes/Contact/Contact';

const ContactPage = () => (
    <Layout>
        <Contact />
    </Layout>
);

export default ContactPage;
