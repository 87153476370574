import React from 'react';

import heroStyles from '../Home/hero.module.scss';

const Contact = () => {
    return (
        <div className={heroStyles.wrapper}>
            <h1>Email: waiyar@gmail.com</h1>
        </div>
    );
};

export default Contact;
